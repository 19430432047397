.MoviesSlider {
    @include swiper-pagination-bullet;
    @include swiper-pagination-bullet-active;
    @include swiper-button-disabled;

    @include swiper-container {
        @extend %home-card-sliders-container;
    }

    @include swiper-pagination-bullets {
        @extend %home-card-sliders-bullets;
    }

    &__header {
        @extend %home-card-sliders-header;
    }

    &__pagination {
        margin-top: 48px;
    }

    &__item {
        @extend %home-card-sliders-item;
    }
}

.NewsCard {
    text-align: left;

    &__picture {
        position: relative;
        display: block;
        padding-top: aspect-ratio(368, 200);
        margin-bottom: 16px;
        border-radius: 10px;
        overflow: hidden;
    }

    &__image {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        size: 100%;
    }

    &__meta {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height: 1.3;
    }

    &__title {
        @extend %heading-small;

        margin-top: 16px;
        transition: color 0.2s ease-in-out;

        &:hover {
            color: $color-new-green;
        }
    }

    &__description {
        margin-top: 16px;
        line-height: 1.6;
    }
}

.NewsFilterLink {
    font-weight: $font-weight-bold;
    border-bottom: 1px dashed;
    transition: opacity 0.2s ease-in-out;

    &--active,
    &:hover,
    &:focus,
    &:active {
        opacity: 0.5;
    }

    &--active {
        cursor: default;
    }

    &--orange {
        color: $color-orange;
        border-color: $color-orange;

        &:hover {
            color: $color-orange;
        }
    }

    &--darkPink {
        color: $color-dark-pink;
        border-color: $color-dark-pink;

        &:hover {
            color: $color-dark-pink;
        }
    }

    &--newGreen {
        color: $color-new-green;
        border-color: $color-new-green;

        &:hover {
            color: $color-new-green;
        }
    }
}

.EventCard {
    transition: transform 0.2s ease-in-out;
    text-align: left;

    &__picture {
        position: relative;
        background-color: $color-grayish;
        display: block;
        padding-top: aspect-ratio(282, 400);
        margin-bottom: 16px;
        border-radius: 10px;
        overflow: hidden;
    }

    &__image {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        size: 100%;
    }

    &__date {
        color: $color-new-green;
        font-weight: $font-weight-medium;
        line-height: 1.4;
    }

    &__title {
        @extend %heading-small;

        margin-top: 8px;
        transition: color 0.2s ease-in-out;
    }
}

.EventCardLink {
    &:hover,
    &:focus,
    &:active {
        .EventCard {
            @media (hover: hover) {
                transform: translateY(-#{$slider-card-hover-translate});
            }

            &__title {
                @media (hover: hover) {
                    color: $color-new-green;
                }
            }
        }
    }
}

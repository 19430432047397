.HomePage {
    padding-top: 40px;

    &__eventsSlider {
        margin-top: 66px;

        @include vw-medium-down {
            margin-top: 32px;
        }

        @include vw-small-down {
            margin-top: 20px;
        }
    }

    &__newsSlider {
        margin-top: 98px;

        @include vw-small-down {
            margin-top: 136px;
        }
    }

    &__video {
        margin-top: 128px;

        @include vw-xxsmall-down {
            margin-top: 80px;
        }
    }

    &__moviesSlider {
        margin-top: 138px;

        @include vw-small-down {
            margin-top: 80px;
        }
    }

    &__ticker {
        margin-top: 70px;

        @include vw-small-down {
            margin-bottom: 132px;
        }
    }

    &__brandsList {
        margin-top: 160px;
        overflow: hidden;

        @include vw-small-down {
            margin-top: 80px;
        }
    }
}

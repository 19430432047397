.PromoTicker {
    position: relative;

    &__svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 48px;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }

    &__background {
        @extend %full-size-image-wrapper;

        padding-top: 306px;

        @include vw-small-down {
            padding-top: 176px;
        }
    }

    &__backgroundImage {
        @extend %full-size-image;

        &--svg {
            object-fit: contain;

            @include vw-small-down {
                object-fit: cover;
            }
        }
    }

    &__button {
        @extend .uk-button, .uk-button-large, .uk-button-rounded, .uk-button-light-pink, .uk-button-rimmed;

        position: absolute;
        z-index: 1;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;

        @include vw-small-down {
            top: calc(100% - 20px);
            bottom: auto;
        }

        &:hover {
            background-color: #dd4e8c;
        }

        &:focus {
            background-color: #ff7cb5;
            box-shadow: inset 0px 0px 10px #c93b78;
        }

        &:active {
            background-color: #c93b78;
        }
    }
}

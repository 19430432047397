.BrandsSlider {
    position: relative;
    display: flex;
    height: 140px;
    align-items: center;
    animation: endless-scroll linear infinite;

    @include vw-xsmall-down {
        height: 106px;
    }

    @media (hover: hover) {
        &:hover {
            animation-play-state: paused;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__item {
        flex-shrink: 0;
        overflow: hidden;
        margin-right: 30px;
        transition: transform 0.3s ease-in-out;

        @media (hover: hover) {
            &:hover {
                transform: scale(1.2);
            }
        }

        &:active {
            transform: scale(1.2);
        }
    }
}

.BrandSlide {
    position: relative;

    &__image {
        // TODO: change hover
        max-width: 220px;
    }
}

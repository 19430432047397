.EventsSlider {
    @include swiper-pagination-bullet;
    @include swiper-pagination-bullet-active;
    @include swiper-button-disabled;

    @include swiper-container {
        @extend %home-card-sliders-container;

        padding-top: $slider-card-hover-translate;
    }

    @include swiper-pagination-bullets {
        @extend %home-card-sliders-bullets;
    }

    &__header {
        margin-bottom: 32px;

        @include vw-medium-down {
            margin-bottom: 16px;
        }

        @include vw-small-down {
            margin-bottom: 8px;
        }
    }

    &__pagination {
        margin-top: 48px;
    }

    &__item {
        @extend %home-card-sliders-item;
    }
}

.PromoSlider {
    $swiper-container-padding-bottom: 32px;
    $block: &;

    --bullet-active-color: #{$color-dark-pink};

    @include swiper-pagination-bullet;
    @include swiper-pagination-bullet-active;

    @include swiper-container {
        padding-bottom: $swiper-container-padding-bottom;
    }

    @include swiper-pagination-bullets {
        position: absolute;
        z-index: 1;
        background-color: $color-white;
        padding: 8px 16px;
        border-radius: 40px;
        box-shadow: 0 4px 20px rgba(71, 16, 69, 0.15);
        bottom: $swiper-container-padding-bottom;
        left: 50%;
        transform: translate(-50%, 50%);
    }

    &__item {
        max-width: 1080px;
        border-radius: 20px;
        overflow: hidden;

        @include vw-xlarge-down {
            max-width: none;
            border-radius: 0;
        }
    }

    &__nav {
        position: absolute;
        left: 50%;
        bottom: $swiper-container-padding-bottom;
        z-index: 1;
        display: flex;
        justify-content: flex-end;
        max-width: 1080px;
        width: 100%;
        padding: 32px;
        transform: translateX(-50%);

        @include vw-medium-down {
            display: none;
        }
    }

    &__navBtn {
        @extend .uk-button, .uk-button-round;

        display: inline-flex;
        justify-content: center;
        align-items: center;

        & svg {
            transition: stroke 0.2s ease-in-out;
        }

        &--prev,
        &--next {
            @extend .uk-button-shadow;

            &:hover {
                background-color: #f4f1f4;
            }

            &:focus {
                background-color: #f0f0f0;
            }

            &:active {
                background-color: #471045;

                & svg {
                    stroke: $color-white;
                }
            }
        }

        &--prev {
            @extend .uk-button-nav-prev;

            margin-right: 16px;
        }

        &--next {
            @extend .uk-button-nav-next;
        }
    }

    &__picture {
        position: relative;
        display: block;
        padding-top: aspect-ratio(1080, 480);
        width: 100%;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

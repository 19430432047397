.MovieCard {
    text-align: left;

    &__picture {
        position: relative;
        display: block;
        padding-top: aspect-ratio(282, 400);
        margin-bottom: 16px;
        border-radius: 10px;
        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            display: block;
            size: 100%;
            left: 0;
            top: 0;
            transition: background-color 0.3s ease-in-out;
        }
    }

    &__image {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        size: 100%;
    }

    &__title {
        @extend %heading-small;

        margin-top: 8px;
        transition: color 0.3s ease-in-out;
    }
}

.MovieCardLink {
    &:hover,
    &:focus,
    &:active {
        .MovieCard {
            &__picture {
                &::after {
                    background-color: transparentize($color-new-green, 0.6);
                }
            }

            &__title {
                color: $color-new-green;
            }
        }
    }
}

.SliderHeader {
    display: flex;
    align-items: center;

    &__title {
        @extend %heading-large;

        margin-right: auto;
    }

    &__link {
        @extend .uk-button, .uk-button-small, .uk-button-greenish, .uk-button-rounded;
    }

    &__nav {
        font-size: 0;
        margin-left: 32px;

        @include vw-xsmall-down {
            display: none;
        }
    }

    &__navButton {
        @extend .uk-button, .uk-button-round, .uk-button-border-dashed, .uk-button-transparent, .uk-button-nav;

        display: inline-flex;
        justify-content: center;
        align-items: center;

        &--prev {
            @extend .uk-button-nav-prev;

            margin-right: 16px;
        }

        &--next {
            @extend .uk-button-nav-next;
        }
    }
}

// Most common slider header margins
%home-card-sliders {
    &-container {
        @include vw-small-down {
            margin-x: -$container-padding-horizontal;
            padding-x: $container-padding-horizontal;
        }
    }

    &-bullets {
        @include vw-small-down {
            display: none;
        }
    }

    &-header {
        margin-bottom: 48px;

        @include vw-medium-down {
            margin-bottom: 32px;
        }

        @include vw-small-down {
            margin-bottom: 24px;
        }
    }

    &-item {
        @include vw-small-down {
            width: 304px;
            max-width: 81vw;
        }
    }
}

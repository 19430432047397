:root {
    --bullet-color: #{$color-gray};
    --bullet-active-color: #{$color-dark-purple};
}

%swiper-container {
    text-align: center;
}

%swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0;
}

%swiper-pagination-bullet {
    display: block;
    width: 8px;
    height: 8px;
    background-color: var(--bullet-color);
    border-radius: 50%;
    transform-origin: center;
    transition: transform 0.3s ease-in-out, background-color 0.2s ease-in-out;
    outline: none;
    cursor: pointer;

    &:first-child,
    &:last-child {
        transform: scale(0.75);
    }

    &:not(:last-child) {
        margin-right: 8px;
    }

    &:hover {
        --bullet-color: #{rgba($color-new-green, 0.4)};
    }

    &-active {
        background-color: var(--bullet-active-color);
        pointer-events: none;

        html:root & {
            transform: scale(1);
        }
    }
}

%swiper-button {
    &-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

@mixin swiper-container() {
    .swiper-container {
        @extend %swiper-container;

        @content;
    }
}

@mixin swiper-pagination-bullets() {
    .swiper-pagination-bullets {
        @extend %swiper-pagination-bullets;

        @content;
    }
}

@mixin swiper-pagination-bullet() {
    .swiper-pagination-bullet {
        @extend %swiper-pagination-bullet;

        @content;
    }
}

@mixin swiper-pagination-bullet-active() {
    .swiper-pagination-bullet-active {
        @extend %swiper-pagination-bullet-active;

        @content;
    }
}

@mixin swiper-button-disabled {
    .swiper-button-disabled {
        @extend %swiper-button-disabled;

        @content;
    }
}
